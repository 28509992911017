import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  styled,
  Typography
} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {useAuthentication} from '../../hooks/useAuthentication'

import {AuthenticationDiagramCard} from './AuthenticationDiagramCard'

const faqTranslations = [
  {
    question: 'faq.aboutApi.question',
    answer: 'faq.aboutApi.answer',
    ariaControls: 'panel-aboutApi-content',
    id: 'panel-aboutApi-header',
    public: true
  },
  {
    question: 'faq.aboutAuthentication.question',
    answer: 'faq.aboutAuthentication.answer',
    ariaControls: 'panel-aboutAuthentication-content',
    id: 'panel-aboutAuthentication-header',
    public: true
  },
  {
    question: 'faq.aboutDataFormat.question',
    answer: 'faq.aboutDataFormat.answer',
    ariaControls: 'panel-aboutDataFormat-content',
    id: 'panel-aboutDataFormat-header',
    public: true
  },
  {
    question: 'faq.aboutRateLimit.question',
    answer: 'faq.aboutRateLimit.answer',
    ariaControls: 'panel-aboutRateLimit-content',
    id: 'panel-aboutRateLimit-header',
    public: true
  },
  {
    question: 'faq.aboutUptimeGuaranty.question',
    answer: 'faq.aboutUptimeGuaranty.answer',
    ariaControls: 'panel-aboutUptimeGuaranty-content',
    id: 'panel-aboutUptimeGuaranty-header',
    public: true
  },
  {
    question: 'faq.aboutErrorHandling.question',
    answer: 'faq.aboutErrorHandling.answer',
    ariaControls: 'panel-aboutErrorHandling-content',
    id: 'panel-aboutErrorHandling-header',
    public: true
  },
  {
    question: 'faq.aboutSupport.question',
    answer: 'faq.aboutSupport.answer',
    ariaControls: 'panel-aboutSupport-content',
    id: 'panel-aboutSupport-header',
    public: true
  },
  {
    question: 'faq.aboutApiUsages.question',
    answer: 'faq.aboutApiUsages.answer',
    ariaControls: 'panel-aboutApiUsages-content',
    id: 'panel-aboutApiUsages-header',
    public: true
  },
  {
    question: 'faq.aboutPricing.question',
    answer: 'faq.aboutPricing.answer',
    ariaControls: 'panel-aboutPricing-content',
    id: 'panel-aboutPricing-header',
    public: false
  },
  {
    question: 'faq.aboutContract.question',
    answer: 'faq.aboutContract.answer',
    ariaControls: 'panel-aboutContract-content',
    id: 'panel-aboutContract-header',
    public: false
  }
]

const QuestionTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '24px',
  component: 'span'
})

const AnswerTypography = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '26px',
  whiteSpace: 'pre-line'
})

const filterFAQByAuthState = (isUserLoggedIn: boolean) => {
  if (isUserLoggedIn) {
    return faqTranslations
  }

  return faqTranslations.filter((f) => f.public)
}

export const FAQCard = () => {
  const {t} = useTranslation()
  const {userAuthenticated} = useAuthentication()
  const filteredList = filterFAQByAuthState(userAuthenticated)
  return (
    <div data-test-id="component-faq">
      {filteredList?.map((faqTranslation) => (
        <Accordion key={faqTranslation.id} data-test-id={`according-faq-${faqTranslation.id}`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={faqTranslation.ariaControls}
            id={faqTranslation.id}
          >
            <QuestionTypography variant="subtitle1">
              {t(faqTranslation.question)}
            </QuestionTypography>
          </AccordionSummary>

          <AccordionDetails data-test-id={`according-details-faq-${faqTranslation.ariaControls}`}>
            <AnswerTypography>
              <Trans
                i18nKey={faqTranslation.answer}
                components={{
                  bold: <strong />,
                  hyperlink: (
                    <Link
                      href="https://www.rfc-editor.org/rfc/rfc6749#section-4.3"
                      target="_blank"
                      data-test-id={`according-details-faq-${faqTranslation.ariaControls}-link`}
                      rel="noreferrer"
                      variant="body2"
                    >
                      Resource Owner Password Flow
                    </Link>
                  ),
                  media: <AuthenticationDiagramCard />
                }}
              />
            </AnswerTypography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}
