import {Company} from '@hconnect/apiclient'
import {AxiosInstance} from 'axios'
import {QueryFunction, useQuery} from 'react-query'

import {useDatalinkSecureApi} from './useDatalinkSecureApi'

type UseMemberCompaniesQueryKey = ['companies', 'member', {userId: string}]

const companiesFetcher =
  (secureApi: AxiosInstance): QueryFunction<Company[], UseMemberCompaniesQueryKey> =>
  async ({queryKey}) => {
    const [, , {userId}] = queryKey
    const response = await secureApi.get<Company[]>(`/companies?userId=${userId}`)
    return response.data
  }

export const useCompanies = (userId: string, isDataLinkDeveloper: boolean | undefined) => {
  const secureApi = useDatalinkSecureApi()
  const result = useQuery(['companies', 'member', {userId}], companiesFetcher(secureApi), {
    retry: false,
    enabled: !!userId && !!isDataLinkDeveloper
  })

  return {
    userAssociatedCompanies: result.data ?? [],
    isCompanyLoadedSuccessfully: result.isSuccess,
    isCompanyRequestLoading: result.isLoading,
    isCompanyRequestError: result.isError,
    isUserAssociatedWithCompany: isDataLinkDeveloper ? (result?.data?.length ?? 0) > 0 : undefined
  }
}
