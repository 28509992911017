import {useAuth} from '@hconnect/common/authentication/context'
import {LoadingSwitcher} from '@hconnect/uikit'
import {Box, Breadcrumbs as BreadCrumbs, Button, makeStyles, Typography} from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {NavLink, useHistory} from 'react-router-dom'

import {getAppName, getCompanyId} from '../common/app'
import {getNonRevokedCredentials} from '../common/credentials'
import {setDeveloperAppAnalyticEvent} from '../common/eventTracker'
import {getManageableApiProducts} from '../common/permission'
import {getAllEnabledProducts} from '../common/products'
import {ApiProductList} from '../components/apiProducts/ApiProductList'
import {AppDetailOverviewCard} from '../components/apps/AppDetailOverviewCard'
import {AppKeysCard} from '../components/apps/AppKeysCard'
import {DeleteAppConfirmation} from '../components/apps/DeleteAppConfirmation'
import {RevokeAppKeyConfirmation} from '../components/apps/RevokeAppKeyConfirmation'
import {ApigeeApiProducts, ApigeeApp} from '../declarations'
import {useCreateAppKeyMutation, useRevokeAppKeyMutation} from '../hooks/useAppKeys'
import {useDeleteAppMutation, useModifyAppMutation} from '../hooks/useApps'
import {useCommonButtonStyles} from '../hooks/useCommonButtonStyles'
import {useDatalinkSnackbar} from '../hooks/useDatalinkSnackbar'
import {useDatalinkPermissions} from '../hooks/usePermission'
import {useProductAssignment} from '../hooks/useProductAssignment'
import {useDatalinkRoles} from '../hooks/useRoles'
import {useTracking} from '../hooks/useTracking'
import {dataLinkRoutes} from '../routes'

export interface AppDetailProps {
  apiRecord?: ApigeeApiProducts
  appRecord: ApigeeApp
}

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    color: '#8dbbd9',
    marginBottom: 5,
    marginTop: -20
  },
  link: {
    color: '#8dbbd9',
    fontWeight: 400
  },
  separator: {
    fontSize: 25,
    marginBottom: 2,
    opacity: 0.5
  },
  appTitle: {
    lineHeight: '46.6px'
  }
}))

const hasActiveKey = (app: ApigeeApp) => getNonRevokedCredentials(app.credentials).length > 0

export const AppDetailCard = (props: AppDetailProps) => {
  const classes = useStyles()
  const history = useHistory()
  const {t} = useTranslation()
  const {mutateAsync: deleteApp, isLoading: isLoading} = useDeleteAppMutation()
  const {mutateAsync: mutateAppDescription} = useModifyAppMutation()
  const [dialogOpened, setDialogOpened] = useState(false)
  const {mutateAsync, isLoading: isAppRevokingLoading} = useRevokeAppKeyMutation()

  const [openDeleteAppModal, setOpenDeleteAppModal] = useState<boolean>(false)
  const [consumerKey, setConsumerKey] = useState('')
  const commonButtonClasses = useCommonButtonStyles()
  const assignProductAsync = useProductAssignment()
  const {mutateAsync: createAppKeyAsync, isLoading: isCreateAppKeyLoading} =
    useCreateAppKeyMutation()
  const {decodedToken} = useAuth()
  const {apiRecord, appRecord: app} = props
  const {enqueueSnackbar} = useDatalinkSnackbar()
  const hasEnabledProducts =
    getAllEnabledProducts(getNonRevokedCredentials(app.credentials)).length > 0
  const {isFetching: isPermissionFetching, permissions: permissions} = useDatalinkPermissions()
  const manageableProducts = getManageableApiProducts(permissions)
  const {trackEvent} = useTracking(setDeveloperAppAnalyticEvent)

  const {
    isFetching: isRolesQueryFetching,
    isIntegrationPartner,
    isDatalinkDeveloper
  } = useDatalinkRoles()

  const handleOpenDeleteAppDialogEvent = () => {
    setOpenDeleteAppModal(true)
  }

  const handleCancelDialogEvent = () => {
    setOpenDeleteAppModal(false)
  }

  const handleRevoke = async (key: string) => {
    try {
      await mutateAsync(['datalink/appkeys', {appName: app.name || '', key}])
    } finally {
      setDialogOpened(false)
      setConsumerKey('')
      trackEvent({
        operationName: 'dlAppKeyRevoke'
      })
    }
  }

  const createNewAppKeyAsync = async (productNames: string[]) => {
    await createAppKeyAsync([
      'datalink/appkeys',
      {
        attributes: app.attributes,
        appName: app.name || '',
        country: decodedToken?.country_code ?? '',
        productNames: productNames
      }
    ])
  }

  const handleCreateAppKey = async () => {
    const apiProducts: string[] = !isDatalinkDeveloper
      ? getAllEnabledProducts(getNonRevokedCredentials(app.credentials))
      : manageableProducts

    await createNewAppKeyAsync(apiProducts)
    trackEvent({
      operationName: 'dlAppKeyCreate'
    })
  }

  const toggleProduct = async (productName: string, enabled: boolean) => {
    if (!hasActiveKey(app)) {
      await createNewAppKeyAsync([productName])
    }
    await assignProductAsync({
      app,
      productName,
      enabled
    })
  }

  const handleRevokeAppKey = (key: string) => {
    setDialogOpened(true)
    setConsumerKey(key)
  }

  const handleAppDetailFormSubmit = async (app: ApigeeApp) => {
    try {
      await mutateAppDescription([
        'datalink/apps',
        {
          app: {
            payload: {
              name: getAppName(app),
              description: app.description || '',
              companyId: getCompanyId(app)
            },
            uniqueName: app.name
          }
        }
      ])
      enqueueSnackbar(t('appOverview.appDetail.notifications.developerAppSaveSuccess'), 'success')
    } catch (error) {
      enqueueSnackbar(t('appOverview.appDetail.notifications.developerAppSaveError'), 'error')
    }
    trackEvent({
      operationName: 'dlAppSave'
    })
  }

  const handleDeleteAppEvent = async () => {
    await deleteApp(['datalink/apps', {appName: app.name}], {
      onSuccess: () => history.replace(dataLinkRoutes.AppOverviewPage)
    }).finally(() => {
      setOpenDeleteAppModal(false)
      trackEvent({
        operationName: 'dlAppDelete'
      })
    })
  }

  return (
    <LoadingSwitcher
      isLoading={
        isLoading ||
        isRolesQueryFetching ||
        isPermissionFetching ||
        isAppRevokingLoading ||
        isCreateAppKeyLoading
      }
      variant="overlay"
    >
      <BreadCrumbs
        aria-label="breadcrumb"
        className={classes.breadcrumbs}
        maxItems={3}
        separator={<span className={classes.separator}></span>}
      >
        <NavLink
          className={classes.link}
          exact
          key={dataLinkRoutes.LandingPage}
          to={dataLinkRoutes.LandingPage}
        >
          <HomeIcon fontSize="inherit" />
        </NavLink>
        <NavLink
          className={classes.link}
          exact
          key={dataLinkRoutes.AppOverviewPage}
          to={dataLinkRoutes.AppOverviewPage}
        >
          {t('appOverview.appDetail.BreadCrumbs.appListLabel')}
        </NavLink>
      </BreadCrumbs>
      <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
        <Typography className={classes.appTitle} variant="h2">
          {getAppName(app)}
        </Typography>
        <Box>
          <Button
            className={commonButtonClasses.button}
            variant="outlined"
            data-test-id="app-detail-btn-save-app"
            startIcon={<CheckIcon />}
            type="submit"
            form="app-detail-form"
          >
            Save
          </Button>
          <Button
            className={commonButtonClasses.button}
            variant="outlined"
            data-test-id="app-detail-btn-delete-app"
            aria-haspopup="true"
            aria-controls="delete-app-confirmation-dialog"
            startIcon={<DeleteIcon />}
            onClick={handleOpenDeleteAppDialogEvent}
          >
            {t('appOverview.appDetail.deleteButtonLabel')}
          </Button>
        </Box>
      </Box>

      <DeleteAppConfirmation
        onDeleteAppEvent={handleDeleteAppEvent}
        onCancel={handleCancelDialogEvent}
        open={openDeleteAppModal}
        appName={app.name}
      />
      <RevokeAppKeyConfirmation
        onCancel={() => setDialogOpened(false)}
        isOpen={dialogOpened}
        onRevokeAppKeyCallback={() => void handleRevoke(consumerKey)}
      />
      <AppDetailOverviewCard appRecord={app} onSubmit={handleAppDetailFormSubmit} />

      {app.credentials !== undefined && (
        <AppKeysCard
          keys={app.credentials}
          onRevokeAppKey={handleRevokeAppKey}
          onAddAppKey={handleCreateAppKey}
          isAddAppKeyButtonVisible={isDatalinkDeveloper || hasEnabledProducts}
        />
      )}

      {isIntegrationPartner && (
        <ApiProductList
          apis={apiRecord}
          appCredentials={app.credentials}
          toggleProduct={(productName, enabled) => {
            void toggleProduct(productName, enabled)
          }}
        />
      )}
    </LoadingSwitcher>
  )
}
