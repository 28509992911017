import {Company} from '@hconnect/apiclient'
import {useAuth} from '@hconnect/common/authentication/context'
import {LoadingSwitcher} from '@hconnect/uikit'
import {Done, Close} from '@mui/icons-material'
import {
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  MenuItem,
  StandardTextFieldProps
} from '@mui/material'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from 'react-query'

import {setDeveloperAppAnalyticEvent} from '../common/eventTracker'
import {getManageableApiProducts} from '../common/permission'
import {AppFormInputs} from '../declarations'
import {useCreateAppMutation} from '../hooks/useApps'
import {useDatalinkPermissions} from '../hooks/usePermission'
import {useDatalinkRoles} from '../hooks/useRoles'
import {useTracking} from '../hooks/useTracking'

type CreateAppModalProps = {
  open: boolean
  onClose: () => void
}

const CompaniesDropdown = ({
  companies,
  ...otherProps
}: StandardTextFieldProps & {
  companies: Company[]
}) => {
  const {t} = useTranslation()
  return (
    <TextField
      id="outlined-select-currency"
      select
      label={t('appOverview.newApp.companyTextFieldLabel')}
      defaultValue={companies.length === 1 ? companies[0].id : ''}
      data-test-id="create-app-input-company"
      margin="dense"
      variant="standard"
      {...otherProps}
      fullWidth
    >
      {companies.map((company: Company) => (
        <MenuItem key={company.id} value={company.id}>
          {company.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export const CreateAppConfirmationDialog = (props: CreateAppModalProps) => {
  const {onClose, open, ...other} = props
  const {t} = useTranslation()
  const {decodedToken} = useAuth()
  const queryClient = useQueryClient()
  const {mutateAsync: createApp, isLoading: isLoading} = useCreateAppMutation()
  const {
    isFetching: isRolesQueryFetching,
    isDatalinkDeveloper,
    assignedCompanies
  } = useDatalinkRoles()
  const {isFetching: isPermissionFetching, permissions: permissions} = useDatalinkPermissions()
  const manageableProducts = getManageableApiProducts(permissions)
  const {trackEvent} = useTracking(setDeveloperAppAnalyticEvent)

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset
  } = useForm<AppFormInputs>()

  const onSubmit = async (app: AppFormInputs) => {
    await createApp([
      'datalink/apps',
      {
        app: {
          payload: {
            ...app,
            country: decodedToken?.country_code ?? '',
            apiProducts: manageableProducts
          }
        }
      }
    ])
    handleCancel()
    trackEvent({
      operationName: 'dlAppCreate'
    })
    await queryClient.refetchQueries({queryKey: ['datalink', 'apps']})
  }

  const handleCancel = () => {
    reset({name: '', description: '', companyId: ''})
    onClose()
  }

  return (
    <>
      <Dialog maxWidth="xs" open={open} {...other} data-test-id="create-app-confirm-dialog">
        <LoadingSwitcher
          isLoading={isLoading || isRolesQueryFetching || isPermissionFetching}
          variant="overlay"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle> {t('appOverview.newApp.dialogTitle')}</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                id="appName"
                label={t('appOverview.appDetail.appNameLabel')}
                error={!!errors?.name}
                type="text"
                fullWidth
                placeholder={t('appOverview.appDetail.appNameTextFiledPlaceholder')}
                variant="standard"
                helperText={errors?.name?.message}
                inputProps={{
                  ...register('name', {
                    required: true,
                    pattern: {
                      value: /^[A-Za-z][A-Za-z0-9_ \-.]*$/,
                      message: t('appOverview.newApp.invalidAppName')
                    }
                  })
                }}
                data-test-id="create-app-input-appname"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{mr: '20px'}} disableTypography>
                      *
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                margin="dense"
                id="appDescription"
                label={t('appOverview.appDetail.appDescriptionLabel')}
                multiline
                fullWidth
                minRows={4}
                variant="standard"
                placeholder={t('appOverview.appDetail.appDescriptionTextFiledPlaceholder')}
                inputProps={{
                  maxLength: 100,
                  ...register('description')
                }}
                data-test-id="create-app-input-description"
              />

              {isDatalinkDeveloper && (
                <>
                  <CompaniesDropdown
                    companies={assignedCompanies}
                    error={!!errors?.companyId}
                    inputProps={{
                      ...register('companyId', {required: true}),
                      readOnly: assignedCompanies.length === 1
                    }}
                  />
                  <Chip
                    sx={{mt: 1}}
                    label={`${manageableProducts}`}
                    data-test-id="create-app-api-product"
                    variant="outlined"
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                startIcon={<Close />}
                onClick={handleCancel}
                data-test-id="create-app-btn-cancel"
              >
                {t('appOverview.newApp.cancelButton')}
              </Button>
              <Button
                variant="contained"
                startIcon={<Done />}
                type="submit"
                data-test-id="create-app-btn-submit"
                disabled={isLoading}
              >
                {t('appOverview.newApp.createButtonText')}
              </Button>
            </DialogActions>
          </form>
        </LoadingSwitcher>
      </Dialog>
    </>
  )
}
