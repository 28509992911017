import {useAuth} from '@hconnect/common/authentication/context'
import {AxiosInstance} from 'axios'
import {QueryFunction, useQuery} from 'react-query'

import {
  getBusinessLine,
  getCountryIds,
  getCustomerId,
  getRoleTypes,
  hasDatalinkAdminRole,
  hasDatalinkDeveloperRole,
  hasIntegrationPartnerRole
} from '../common/roles'
import {Role} from '../declarations'

import {useCompanies} from './useCompanies'
import {useDatalinkSecureApi} from './useDatalinkSecureApi'

type RolesQueryKey = ['roles', {userId: string | undefined}]

const rolesFetcher =
  (secureApi: AxiosInstance): QueryFunction<Role[], RolesQueryKey> =>
  async ({queryKey}) => {
    const [, {userId}] = queryKey
    const response = await secureApi.get<Role[]>(`/roles?userId=${userId}`)
    return response.data
  }

const useUserRoles = (userId: string | undefined) => {
  return useQuery(['roles', {userId}], rolesFetcher(useDatalinkSecureApi()), {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!userId
  })
}

export const useDatalinkRoles = () => {
  const {decodedToken} = useAuth()
  const {data, isError, isFetching} = useUserRoles(decodedToken?.user_id)
  const {isCompanyRequestLoading, isUserAssociatedWithCompany, userAssociatedCompanies} =
    useCompanies(decodedToken?.user_id || '', hasDatalinkDeveloperRole(data as Role[]))
  const roles = data as Role[]

  return {
    isError: isError,
    isFetching: isFetching || isCompanyRequestLoading,
    roles: roles,
    roleTypes: getRoleTypes(roles),
    businessLine: getBusinessLine(roles),
    isDatalinkDeveloper: hasDatalinkDeveloperRole(roles),
    isIntegrationPartner: hasIntegrationPartnerRole(roles),
    isCompanyAssigned: isUserAssociatedWithCompany,
    assignedCompanies: userAssociatedCompanies,
    customerId: getCustomerId(roles),
    countryIds: getCountryIds(roles),
    hasDatalinkAdminRole: hasDatalinkAdminRole(roles)
  }
}
